/**
 * Axios instance preset
 */
// Dependencies
import axios from 'axios';
import store from '../store';

// Settings
const protocol = 'https://';
const host = 'fundacionmasluz.com';
const path = '/api/';
const timeout = 30000;

// Vars
export const originalURL = `${protocol}${host}`;
export const baseURL = `${originalURL}${path}`;

const http = axios.create({
  baseURL,
  timeout,
  withCredentials: true,
});

http.interceptors.request.use((options) => {
  const { access } = store.state.auth;
  if (access) {
    // eslint-disable-next-line no-param-reassign
    options.headers.token = access;
  }
  return options;
});
http.interceptors.response.use(null, async (error) => {
  if (error?.response) {
    const { response: { data, status, config: { url } } } = error;
    if (data?.error && status === 401) {
      const errorDetail = /token/ig.test(data.error);
      const isLogout = /signout/ig.test(url);

      if (!isLogout && errorDetail) {
        const refreshToken = await store.dispatch('auth/refreshToken');
        if (refreshToken) {
          const config = { ...error.config };
          config.headers.token = refreshToken;
          return axios.request(config);
        }
        store.dispatch('app/setRenewSessionFormVisibility', true);
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({ response: { data: { error: 'La sesión ha caducado' } } });
      }
    }
  }
  return Promise.reject(error);
});

export default http;
